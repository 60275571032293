import React, { useState, useEffect } from "react";
import "../contactUs/style.css";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { GiCheckMark } from "react-icons/gi";
import Spinner from "../../common/Spinner";
import styled from "styled-components";
import prenatal from "../../../Icons/Avtar/PreConception.png";

const Section = styled.section`
  width: 100%;
  background-image: linear-gradient(
    ${(props) => props.theme.lightBlueShade},
    ${(props) => props.theme.yellowShade}
  );
  color: red;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  position: relative;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 900px;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  margin-top: 28px;
  @media (max-width: 64em) {
    flex-direction: column;
    width: 90%;
  }

  @media (max-width: 48em) {
    flex-direction: column;
    width: 95%;
  }

  @media (max-width: 30em) {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: rebeccapurple;
  @media (max-width: 64em) {
    width: 100%;
  }

  @media (max-width: 48em) {
    width: 100%;
  }

  @media (max-width: 30em) {
    width: 100%;
  }
`;

const Image = styled.img`
  // width: 100%;
  height: auto;

  object-fit: none;
  @media (max-width: 64em) {
    align-self: flex-start;
  }

  @media (max-width: 48em) {
    align-self: flex-start;
  }

  @media (max-width: 30em) {
    align-self: flex-start;
  }
`;

const FormContent = styled.div`
  width: 60%;
  padding: 20px;

  @media (max-width: 64em) {
    width: 100%;
  }

  @media (max-width: 48em) {
    width: 100%;
  }

  @media (max-width: 30em) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxl};
  color: ${(props) => props.theme.buttonColor};
  margin-bottom: 20px;
  text-align: center;
`;

const Phone = styled.h1`
  font-size: ${(props) => props.theme.fontsm};
  color: ${(props) => props.theme.buttonColor};
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const SwitchButton = styled.button`
  background-color: ${(props) =>
    props.active ? props.theme.buttonColor : "#ccc"};
  color: ${(props) => (props.active ? props.theme.body : "#666")};
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 20px;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontsm};
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: ${(props) => props.theme.fontsm};
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: ${(props) => props.theme.fontsm};
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: ${(props) => props.theme.fontmd};
  background-color: ${(props) => props.theme.buttonColor};
  color: ${(props) => props.theme.body};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

async function createRequest(data) {
  const response = await Axios.post(
    `https://us-central1-website-a1336.cloudfunctions.net/api/registerVisitor`,
    data
  );
  return response.data;
}

export const ContactUsForm = () => {
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  const [formStatus, setFormStatus] = React.useState("Submit");
  const [showModal, setShowModal] = useState(false);
  let [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    mobile: "",
    source: "",
    salutation: "",
    jobTitle: "",
  });
  useEffect(() => {
    validateForm();
  }, [formData]);
  const validateForm = () => {
    const { name, email, country, mobile, salutation, jobTitle } = formData;
    if (name && email && mobile && country && salutation && jobTitle) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleModalClose = () => {
    setShowModal(false);
    navigate("/");
  };
  const onSubmit = async (e) => {
    if (isFormValid) {
      try {
        setLoading(true);
        e.preventDefault();
        const { name, email, mobile, salutation, jobTitle } = e.target.elements;
        let conFom = {
          name: name.value,
          email: email.value,
          mobile: mobile.value,
          salutation: salutation.value,
          job_title: jobTitle.value,
          source: "Yoga-Shastra",
        };
        const res = await createRequest(conFom);
        if (res.code === "NP001") {
          setLoading(false);
          setFormStatus("Submit");
          setShowModal(true);
          setTimeout(handleModalClose, 5000);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setLoading(false);

        // Handle error
      }
    }
  };
  return (
    <div className="">
      <Section>
        {loading && <Spinner />}
        <FormContainer>
          <ImageWrapper>
            <Image src={prenatal} alt="Yoga Image" />
          </ImageWrapper>

          <FormContent>
            <Title>Drop us a line</Title>
            <form onSubmit={onSubmit}>
              <InputField
                type="text"
                name="salutation"
                placeholder="Mr*"
                value={formData.salutation}
                onChange={handleChange}
                required
              />
              <InputField
                type="text"
                name="name"
                placeholder="Full Name*"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <InputField
                type="email"
                name="email"
                placeholder="Your Email*"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <InputField
                type="text"
                name="country"
                placeholder="Country Code(+91)*"
                value={formData.country}
                onChange={handleChange}
                required
              />
              <InputField
                type="text"
                name="mobile"
                placeholder="+9188XXXXXX00"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
              <InputField
                type="text"
                name="jobTitle"
                placeholder="Student, Trainer,Yoga Academy,Corporate Yoga"
                value={formData.jobTitle}
                onChange={handleChange}
                required
              />
              <SubmitButton type="submit" disabled={!isFormValid}>
                Submit
              </SubmitButton>
            </form>
            <Phone >You can reach us at +91-7838866331</Phone>
          </FormContent>
        </FormContainer>
      </Section>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        centered={true}
        dialogClassName="modal-center"
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-center mb-4">
            <GiCheckMark size={50} color="green" />
          </div>
          <p>
            <span style={{ fontWeight: "600" }}>
              Thank you for contacting us.
            </span>{" "}
            <br /> One of our representative will contact you shortly.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ContactUsForm;
